/*******************************
         Site Overrides
*******************************/

@font-face {
    font-family: 'Barlow Condensed';
    src: url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500&display=swap');
}

.ui.message {font-size: 0.813rem!important;}
.ui.teal.message {color: #0E566C}
.ui.label {font-family: 'Barlow', sans-serif}
.ui.form .field > label {font-size: 0.875rem!important; font-weight: 600!important}
.ui.icon.input {
    position: relative;
}
.ui.icon.input {
    i.circular.icon.times {
        width: 18px!important;
        height: 18px!important;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.ui.form textarea {
    font-size: 0.875rem!important
}

.ui.checkbox label {
    padding-left: 1.33rem!important;
    font-size: 0.85rem!important;
    font-weight: 500;
}

.ui.pointing.menu {
    font-size: 0.85rem;
    .active.item, .active.item:hover {
        background-color: #36220F;
        color: #fff;
        &:after {
            background: #36220F;
        }
        &:before {
            background: #36220F;
        }
    }
    .active.item:hover:after {
        background-color: #36220F;
    }
}

.ui.pointing.menu .active.item:hover::after {
    background-color: #36220F!important;
}

.ui.selection.active.dropdown:hover, .ui.selection.active.dropdown, .ui.selection.active.dropdown {
    border-color: rgba(0, 0, 0, 0.5)
}

a, a:hover {
    color: unset;
}