/*******************************
    User Variable Overrides
*******************************/

.ui.comments {
    max-width: 100%;
}

.ui.comments .comment .comments {
    padding: 1em 0em 1em 3.8em;
}

.ui.comments .comment .comments  {
    font-size: 0.813rem;
}

.ui.comments .comment .text {
    font-size: 0.813rem;
}

.ui.comments .comment .author {
    font-size: 0.813rem;
}

.ui.comments .comment .metadata {
    font-size: 0.688rem;
    margin-left: 0;
    margin-right: 1rem;
}
.ui.comments .comment .comments .comment .metadata {
    margin-left: 0.5rem;
}

.ui.comments .comment .avatar img {
    border-radius: 100%;
}

.ui.comments .comment .avatar {
    width: 40px;
    height: 40px;
    margin: 0;
    margin-right: 1rem;
}

.ui.comments .comment .comments > .content {
    margin-left: 4.5em;
}