/*******************************
         Site Overrides
*******************************/

.ui.button {
    white-space: nowrap
}

.ui.two.buttons {
    .ui.button:first-child {
        margin-right: 0.25rem;
    }
    .ui.buttons:not(.vertical) > .basic.red.button:not(:first-child) {
        margin-left: 0.25rem;
    }
}