/*******************************
         Site Overrides
*******************************/
.ui.labeled.input > .label {font-size: 0.875rem};

.ui.input {font-size: 0.875rem};

.ui.form textarea {
    font-size: 0.875rem
}

.ui.form input[type="text"]:focus {
    border-color: rgba(0, 0, 0, 0.3);
}

.ui.form .field .ui.input input[type="date"] {
    width: 50%;
}