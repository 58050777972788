/*******************************
         Site Overrides
*******************************/

.ui.toggle.checkbox.settings-checkbox-toggle {
    input:checked ~ label:before {
        background-color: @green!important;
    }
}

.ui.checkbox:focus {
    outline: #10A3A3 1px solid;
}

.ui.toggle.checkbox input {
    width: 2.5rem;
}

.ui.fitted.toggle.checkbox, .ui.fitted.toggle.checkbox {
    width: 2.5rem;
}

.ui.toggle.checkbox input:checked ~ .box:after, .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.25rem;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    width: 2.5rem;
}