/*******************************
         Site Overrides
*******************************/

@font-face {
    font-family: 'Barlow Condensed';
    src: url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500&display=swap');
}

.ui.header {
    text-transform: uppercase;
    font-family: 'Barlow Condensed';
}

@media (min-width: @tabletBreakpoint){
    h3.ui.header {
        font-size: 1.125rem;
    }
    h2.ui.header {
        font-size: 1.25rem;
    }
}

@media (min-width: @computerBreakpoint){
    h2.ui.header {
        font-size: 1.5rem;
    }
    h5.ui.header {
        font-size: 1.2rem;
    }
}

