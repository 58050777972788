/*******************************
        User Overrides
*******************************/

.ui.selection.active.dropdown:hover, .ui.selection.active.dropdown, .ui.selection.active.dropdown {
    border-color: rgba(0, 0, 0, 0.3);
}

.ui.selection.active.dropdown:hover .menu,  .ui.selection.active.dropdown .menu {
    border-color: rgba(0, 0, 0, 0.3);
}