/*******************************
        Site Overrides
*******************************/
.ui.message {font-size: 0.813rem!important;}
.ui.teal.message {
        color: #0E566C; 
        background-color: #F8FFFF; 
        box-shadow: 0px 0px 0px 1px #BDDFE7 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0); 
}

.ui.negative.message {
        color: #E7344C;
        background-color: #FFF6F6;
        box-shadow: 0px 0px 0px 1px #ECCCCC inset, 0px 0px 0px 0px rgba(0, 0, 0, 0,);
}


